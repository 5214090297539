var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-suppliers"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('Suppliers')))]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "to": {
        name: 'SupplierAdd'
      },
      "title": _vm.$t('AddSupplier')
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-plus-circle ")]), _vm._v(" " + _vm._s(_vm.$t('AddSupplier')) + " ")], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _vm.suppliers ? _c('v-card', [_c('v-card-title', [_c('v-spacer'), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    staticClass: "cursorTable",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.suppliers,
      "search": _vm.search,
      "custom-filter": _vm.customFilter,
      "loading": _vm.loadingTable,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    on: {
      "click:row": function ($event) {
        return _vm.goToPageItem($event);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.supplier`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'SupplierGeneral',
              params: {
                supplierUUID: `${item._id}`
              }
            },
            "title": item.name
          }
        }, [_c('v-icon', {
          staticClass: "mb-1 mr-2",
          attrs: {
            "small": "",
            "color": "success"
          }
        }, [_vm._v(" fa fa-check-circle ")]), _vm._v(" " + _vm._s(item.name) + " ")], 1)];
      }
    }, {
      key: `item.city`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'SupplierGeneral',
              params: {
                supplierUUID: `${item._id}`
              }
            },
            "title": item.address.city
          }
        }, [_vm._v(" " + _vm._s(item.address.city) + " ")])];
      }
    }, {
      key: `item.country`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'SupplierGeneral',
              params: {
                supplierUUID: `${item._id}`
              }
            },
            "title": item.address.country
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(`countries.${item.address.country}`)) + " ")])];
      }
    }, {
      key: `item.technologies`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'SupplierGeneral',
              params: {
                supplierUUID: `${item._id}`
              }
            }
          }
        }, [_vm._l(item.technologies, function (materials, technology, index) {
          return [_c('v-divider', {
            key: 'divider' + index
          }), item.technologies ? _c('div', {
            key: 'technology' + index,
            staticClass: "d-flex align-center ma-1 pl-2"
          }, [_c('span', {
            staticClass: "m-1 mr-3"
          }, [_c('v-icon', {
            staticClass: "info--text mb-1 mr-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" fas fa-chevron-right ")]), _vm._v(" " + _vm._s(technology) + " ")], 1), _c('div', {
            staticClass: "buttonMaterial d-flex flex-wrap"
          }, _vm._l(materials, function (material, index) {
            return _c('v-card', {
              key: index,
              staticClass: "grey px-2 py-1 ma-1 white--text",
              attrs: {
                "flat": ""
              }
            }, [_vm._v(" " + _vm._s(_vm.$t(material)) + " ")]);
          }), 1)]) : _vm._e()];
        }), !item.technologies ? _c('div', {
          staticClass: "ml-4"
        }, [_vm._v(" " + _vm._s(_vm.$t('noTechnologyRegistered')) + " ")]) : _vm._e()], 2)];
      }
    }, {
      key: `item.action`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-btn', {
          staticClass: "white--text",
          attrs: {
            "color": "primary",
            "to": {
              name: 'SupplierGeneral',
              params: {
                supplierUUID: `${item._id}`
              }
            },
            "title": _vm.$t('edit')
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-pen ")])], 1)];
      }
    }], null, true)
  })], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }