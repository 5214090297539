import { render, staticRenderFns } from "./pageSuppliers.template.vue?vue&type=template&id=634b2beb&lang=html&"
import script from "./PageSuppliers.vue?vue&type=script&lang=js&"
export * from "./PageSuppliers.vue?vue&type=script&lang=js&"
import style0 from "./pageSuppliers.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports