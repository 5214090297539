<template lang="html" src="./pageSuppliers.template.vue"></template>

<style lang="scss" src="./pageSuppliers.scss"></style>

<script>
import { ApiErrorParser, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageSuppliers.i18n');

export default {
  name: 'PageSuppliers',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      suppliers: [],
      headers: [],
      search: '',
      loadingTable: false,
    };
  },
  created() {
    this.getAllSuppliers();
  },
  mounted() {
    this.headers = [
      { text: this.$t('supplier'), value: 'supplier' },
      { text: this.$t('city'), value: 'city' },
      { text: this.$t('country'), value: 'country' },
      { text: this.$t('Technologies'), value: 'technologies' },
      { text: this.$t('action'), value: 'action' },
    ];
  },
  methods: {
    getAllSuppliers() {
      this.loadingTable = true;
      /**
       * GET ALL SUPPLIERS
       */
      this.$apiInstance
        .getAllSuppliers()
        .then(
          (suppliersData) => {
            this.suppliers = suppliersData;
          },
          (error) => {
            /**
             * ERROR GET ALL SUPPLIERS
             */
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loadingTable = false;
        });
    },
    customFilter(value, search ,item) {
      if (!search) return true;
      search = search.toString().toLowerCase();
      if (item.name.toString().toLowerCase().includes(search)) return true;
      if (item.address) {
        let match = false;
        Object.values(item.address).forEach(val => {
          if (val.toString().toLowerCase().includes(search)) match = true;
        });
        if (match) return true;
      }
      if (item.technologies) {
        let match = false;
        Object.keys(item.technologies).forEach(key => {
          if (key.toString().toLowerCase().includes(search)) match = true;
        });
        Object.values(item.technologies).forEach(val => {
          val.forEach(material => {
            if (this.$t(material).toString().toLowerCase().includes(search)) match = true;
          });
        });
        if (match) return true;
      }
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    goToPageItem(item) {
      this.$router.push({name: 'SupplierGeneral', params: { supplierUUID: `${item._id}`}});
    },
  },
};
</script>
